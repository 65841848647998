"use strict";

var NumberUtils = function NumberUtils() {
  /* THIS FIX */
  var _this = this;
  /* PUBLIC VARIABLES */


  _this.url = null;
  /* PRIVATE VARIABLES */

  /* PUBLIC FUNCTIONS */

  _this.isInt = function (n) {
    return Number(n) === n && n % 1 === 0;
  };

  _this.isFloat = function (n) {
    return Number(n) === n && n % 1 !== 0;
  };

  _this.numberToStringReplaceDotsForCommas = function (x) {
    return x.toString().replace(".", ",");
  };
  /* INITs FUNCTIONS */


  function _construct() {
    init();
  }

  function init() {
    if (!_this.inited) {
      _this.inited = true;
    }
  }
  /* INIT CONSTRUCTOR */


  _construct();
};