"use strict";

var CookieUtils = function CookieUtils() {
  /* THIS FIX */
  var _this = this;
  /* PUBLIC VARIABLES */


  _this.url = null;
  /* PRIVATE VARIABLES */

  /* PUBLIC FUNCTIONS */

  _this.setCookie = function (name, value, days) {
    var expires = "";

    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }

    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  };

  _this.getCookie = function (name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];

      while (c.charAt(0) == ' ') {
        c = c.substring(1, c.length);
      }

      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
  };

  _this.deleteCookie = function (name) {
    document.cookie = name + '=; Max-Age=-99999999;';
  };
  /* INITs FUNCTIONS */


  function _construct() {
    init();
  }

  function init() {
    if (!_this.inited) {
      _this.url = window.location;
      _this.inited = true;
    }
  }
  /* INIT CONSTRUCTOR */


  _construct();
};