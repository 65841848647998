"use strict";

var ArrayUtils = function ArrayUtils() {
  /* THIS FIX */
  var _this = this;
  /* PUBLIC VARIABLES */


  _this.url = null;
  /* PRIVATE VARIABLES */

  /* PUBLIC FUNCTIONS */

  _this.removeValueFromArray = function (array, value) {
    var i = array.indexOf(value);

    if (i != -1) {
      array.splice(i, 1);
    }
  };

  _this.search = function (nameKey, prop, myArray) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i][prop] === nameKey) {
        return myArray[i];
      }
    }

    return null;
  };
  /* INITs FUNCTIONS */


  function _construct() {
    init();
  }

  function init() {
    if (!_this.inited) {
      _this.url = window.location;
      _this.inited = true;
    }
  }
  /* INIT CONSTRUCTOR */


  _construct();
};