"use strict";

var NavigationUtils = function NavigationUtils() {
  /* THIS FIX */
  var _this = this;
  /* PUBLIC VARIABLES */


  _this.url = null;
  /* PRIVATE VARIABLES */

  /* PUBLIC FUNCTIONS */

  _this.loadHashURL = function () {
    if (_this.url.hash) {
      return _this.url.hash.substring(1);
    } else {
      return false;
    }
  };

  _this.removeHashFromURL = function () {
    history.pushState("", document.title, window.location.pathname + window.location.search);
  };
  /* INITs FUNCTIONS */


  function _construct() {
    init();
  }

  function init() {
    if (!_this.inited) {
      _this.url = window.location;
      _this.inited = true;
    }
  }
  /* INIT CONSTRUCTOR */


  _construct();
};