"use strict";

var DateUtils = function DateUtils() {
  /* THIS FIX */
  var _this = this;
  /* PUBLIC VARIABLES */


  _this.url = null;
  /* PRIVATE VARIABLES */

  /* PUBLIC FUNCTIONS */

  _this.getCurrentDateinCZFormat = function () {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!

    var yyyy = today.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }

    return dd + '.' + mm + '.' + yyyy;
  };

  _this.getCZDateFormat = function (dateObj) {
    var showTime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    if (_this._isValidDate(dateObj)) {
      var dd = dateObj.getDate();
      var mm = dateObj.getMonth() + 1; //January is 0!

      var yyyy = dateObj.getFullYear();
      var hours = dateObj.getHours();
      var minutes = dateObj.getMinutes();
      var seconds = dateObj.getSeconds();

      if (dd < 10) {
        dd = '0' + dd;
      }

      if (mm < 10) {
        mm = '0' + mm;
      }

      if (hours < 10) {
        hours = '0' + hours;
      }

      if (minutes < 10) {
        minutes = '0' + minutes;
      }

      if (seconds < 10) {
        seconds = '0' + seconds;
      }

      return dd + '.' + mm + '.' + yyyy + " " + (showTime === true ? hours + ":" + minutes + ":" + seconds : "");
    } else {
      return "";
    }
  };

  _this._isValidDate = function (d) {
    return d instanceof Date && !isNaN(d);
  };

  _this.lasDayInPreviousMonth = function () {
    var d = new Date();
    d.setDate(1);
    d.setHours(-1);
    return d;
  };

  _this.firstDayInPreviousMonth = function () {
    var d = new Date();
    d.setDate(1);
    d.setMonth(d.getMonth() - 1);
    return d;
  };

  _this.getFirstDayOfMonth = function (d) {
    d.setDate(1);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
  };

  _this.getLastDayOfMonth = function (d) {
    d.setMonth(d.getMonth() + 1);
    d.setDate(0);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
  };

  _this.parseCSDateToObject = function (dateString) {
    var dateArray = dateString.split(".");
    return {
      d: dateArray[0],
      m: dateArray[1],
      Y: dateArray[2]
    };
  };

  _this.parseENDateToObject = function (dateString) {
    var dateArray = dateString.split("/");
    return {
      d: dateArray[1],
      m: dateArray[0],
      Y: dateArray[2]
    };
  };
  /* INITs FUNCTIONS */


  function _construct() {
    init();
  }

  function init() {
    if (!_this.inited) {
      _this.url = window.location;
      _this.inited = true;
    }
  }
  /* INIT CONSTRUCTOR */


  _construct();
};